.container {
    flex: 1;
    display: flex;
    overflow: auto;
    align-items: center;
    justify-content: center;
}

.item {
    flex: 1;
    height: 100%;
    max-width: 75%;
}