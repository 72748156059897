.header {
    font-size: large;
    font-weight: bold;
}

.container {
    flex: 1;
    display: flex;
    overflow: hidden;
}

.segmentGroup {
    max-height: 100%;
    display: flex;
}

.item {
    flex: 1;
    height: 100%;
}

.content {
    flex: 1;
}

.importButton.importButton.importButton.importButton {
    color: white;
    margin: 0;
    background-color: var(--main-color);
}