:root {
    /* --main-color: rgb(224, 161, 148); */
    --main-color: #f467a4;
    --main-background-color-dark: #ffe7d6;
    --main-background-color: rgb(255, 246, 241);

}

html, body, #root {
    width: 100%;
    height: 100%;
    color: rgb(65, 68, 109);
}

@font-face {
    font-family: 'Amsternannies';
    src: local('Amsternannies'), url(./fonts/Amsternannies.otf) format('opentype');
}

textarea {
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}

.ui.dimmer {
    background-color: rgba(0,0,0,.25) !important;
}

.ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled {
    opacity: 1;
}

.disabled.field > textarea {
    opacity: .45 !important;
}

.ui.segment {
    /* background-color: var(--main-background-color-light); */
}

.ui.search.dropdown>.text {
    width: 100%;
}