.container {
    /* display: flex;
    overflow: auto;
    flex-direction: column;
    flex: 1; */
}

.tableContainer {
    flex: 1;
}

.headerContainer {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-bottom: 16px;
    justify-content: space-between;
}

.searchInput.searchInput.searchInput.searchInput > input {
    font-size: 9pt;
}

.searchDropdown.searchDropdown.searchDropdown.searchDropdown.searchDropdown {
    font-size: 9pt;
    padding: 0.3em 0.5em;

}

.searchCell.searchCell.searchCell {
    padding: 4px;
}

.table {
    
}

.iconCell {
}

.header {
    font-size: large;
    font-weight: bold;
}

.iconCell:hover {
    cursor: pointer;
    color: var(--main-color);
    /* background-color: rgba(230, 230, 230); */
}

.selectMonthContainer {
    display: flex;
    gap: 16px;
    align-items: center;
}