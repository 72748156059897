.header {
    font-size: large;
    font-weight: bold;
}

.container {
    flex: 1;
    display: flex;
    overflow: hidden;
}

.segmentGroup {
    max-height: 100%;
    display: flex;
}

.item {
    flex: 1;
    height: 100%;
}

.activeFamilyItem {
    flex: 2;
    height: 100%;
    margin-left: 16px;

    overflow: auto;
}

.item:not(:first-child) {
    margin-left: 16px;
}

.menu.menu.menu.menu {
    padding: 0;

    flex: 1;
    overflow: auto;
}

.searchInput.searchInput.searchInput.searchInput {
    padding-bottom: 16px;
}

.cellStyleUsed:hover {
    cursor: pointer;
}

.cellStyleExpired:hover {
    cursor: pointer;
}

.cellStyleRegular:hover {
    cursor: pointer;
}

.cellStyleUsed {
    color: rgb(200, 200, 200);
}

.cellStyleExpired {
    color: rgb(232, 159, 159);
}