.container {
    width: 100%;
    height: 100%;
    background-color: var(--main-color);
    overflow: auto;
    /* background-color: rgb(250,250,250); */
    
    padding: 28px;
    
    display: flex;
    align-items: flex-start;
}

.header {
    color: var(--main-color);
}

.content {
    flex: 0.8;

    margin-left: auto;
    margin-right: auto;

    height: 100%;
}