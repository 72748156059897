.header {
    display: flex;
	align-items: center;
	line-height: 1.2rem;
}

.label.label.label.label {
    color: black;
    width: 100%;
    border-radius: 0;

    display: flex;
    align-items: center;
    gap: 0.5rem;

    svg {
        margin-top: 2.5px;
    }
}

.content {
    padding: 1rem;	
}

.content:last-child {
    margin-bottom: 0;
}

.segment.segment.segment {
    padding: 0;
    margin: -1px;
    margin-bottom: 1em;
    border: 1px solid rgb(230, 230, 230);
}
.segment.segment.segment:last-child {
    margin: 0;
}