.tableContainer {
    flex: 1;
}


.searchInput.searchInput.searchInput.searchInput > input {
    font-size: 9pt;
}

.searchDropdown.searchDropdown.searchDropdown.searchDropdown.searchDropdown {
    font-size: 9pt;
    padding: 0.3em 0.5em;
}

.searchCell.searchCell.searchCell {
    padding: 4px;
}

.table {
    
}

.iconCell {
}

.header {
    font-size: large;
    font-weight: bold;
}

.iconCell:hover {
    cursor: pointer;
    color: var(--main-color);
}