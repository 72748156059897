.container {
    width: 100%;
    height: 100%;
    background-color: var(--main-background-color);
    
    padding-top: 72px;
    padding-bottom: 28px;
    
    display: flex;
    align-items: flex-start;
}

.content {
    flex: 1;
    min-width: 500px; 
    max-width: 95%;
    
    margin-left: auto;
    margin-right: auto;

    height: 100%;
}

.tab {
    height: 100%;
    display: flex;
    flex-direction: column;
}