.header {
    font-size: large;
    font-weight: bold;
}

.container {
    flex: 1;
    display: flex;
    overflow: hidden;
}

.segmentGroup {
    max-height: 100%;
    display: flex;
}

.item {
    flex: 1;
    height: 100%;
}

.item:not(:first-child) {
    margin-left: 16px;
}

.menu.menu.menu.menu {
    padding: 0;

    flex: 1;
    overflow: auto;
}

.searchInput.searchInput.searchInput.searchInput {
    padding-bottom: 16px;
}