.header {
    font-size: large;
    font-weight: bold;
}

.container {
    flex: 1;
    display: flex;
    overflow: hidden;
}

.segmentGroup {
    max-height: 100%;
    display: flex;
}

.item {
    flex: 1;
    height: 100%;
}

.content {
    flex: 1;
}

.statisticGroup.statisticGroup.statisticGroup.statisticGroup {
    margin: 1em;
    padding-left: 4em;
    padding-right: 4em;
}

.statisticValue.statisticValue.statisticValue.statisticValue {
    color: var(--main-color);
}

.statistic.statistic.statistic.statistic {
    margin: auto;
    padding: 1em;
}

.exportButton.exportButton.exportButton.exportButton {
    color: white;
    margin: 0.5em;
    background-color: var(--main-color);
}