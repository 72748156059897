.container {
    width: 100%;
    height: 100%;
    background-color: var(--main-color);
    
    display: flex;
    align-items: center;
}

.formContainer {
    flex: 0.75;
    min-width: 300px; 
    max-width: 500px;
    margin: auto;
    background-color: white;
    border-radius: 8px;
    padding: 24px;

    text-align: center;
}

.header { 
    font-size: 32pt;
    font-family: "Amsternannies";
    color: var(--main-color);
}